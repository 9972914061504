<template>
  <div class="fixed top-0 left-0 w-screen h-screen bg-slate-900">
    <div
      v-if="!isFinished"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center text-full font-heavy"
      :class="currentColor"
    >
      <AnimatedCharacter ref="character" @offscreen="handleOffscreen">
        {{ currentCharacter }}
      </AnimatedCharacter>
    </div>
    <div
      v-if="!isFinished || currentIndex > 0"
      class="absolute top-0 left-0 w-1/2 h-full opacity-0"
      @click="handlePrevious"
    />
    <div
      v-if="!isFinished"
      class="absolute top-0 right-0 w-1/2 h-full opacity-0"
      @click="handleNext"
    />
    <div
      v-if="isFinished"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center text-half font-heavy text-center"
      :class="currentColor"
      @click="handleRestart"
    >
      Great Job!
    </div>
    <div
      v-if="modalIsOpen"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center font-heavy bg-slate-900 text-white"
    >
      <div>
        <div class="text-2xl text-center">Game Mode:</div>
        <div class="flex justify-center text-4xl mt-4">
          <div>
            <input class="hidden peer" type="radio" id="letters" name="mode" value="letters" v-model="mode">
            <label class="inline-block peer-checked:border-white p-4 border-white/0 border-4 rounded-lg" for="letters">ABC</label>
          </div>
          <div class="mx-2">
            <input class="hidden peer" type="radio" id="numbers" name="mode" value="numbers" v-model="mode">
            <label class="inline-block peer-checked:border-white p-4 border-white/0 border-4 rounded-lg" for="numbers">123</label>
          </div>
          <div>
            <input class="hidden peer" type="radio" id="all" name="mode" value="" v-model="mode">
            <label class="inline-block peer-checked:border-white p-4 border-white/0 border-4 rounded-lg" for="all">All</label>
          </div>
        </div>
      </div>
    </div>
    <button
      class="absolute bottom-0 left-0 w-14 h-14 flex justify-center items-center text-white font-heavy"
      @click="toggleModal"
    >
      <template v-if="modalIsOpen">&times;</template>
      <template v-else>&hellip;</template>
    </button>
  </div>
</template>

<script>
import { shuffle } from 'lodash';

import AnimatedCharacter from '@/components/AnimatedCharacter.vue'

const alpha = Array.from(Array(26)).map((_, i) => i + 65);
const alphabet = alpha.map((x) => String.fromCharCode(x));

export default {
  components: {
    AnimatedCharacter,
  },
  data() {
    return {
      isFinished: false,
      currentIndex: 0,
      currentColorIndex: 0,
      numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      letters: alphabet,
      mode: '',
      modalIsOpen: false,
      colors: [
        'text-red-600',
        'text-orange-500',
        'text-yellow-500',
        'text-lime-500',
        'text-green-600',
        'text-sky-500',
        'text-blue-600',
        'text-violet-600',
        'text-fuchsia-500',
        'text-pink-500',
      ],
    };
  },
  computed: {
    shuffledCharacters() {
      return !this.mode ? shuffle([...this.letters, ...this.numbers]) : shuffle(this[this.mode]);
    },
    currentCharacter() {
      return this.shuffledCharacters[this.currentIndex];
    },
    shuffledColors() {
      return shuffle(this.colors);
    },
    currentColor() {
      return this.shuffledColors[this.currentColorIndex];
    },
  },
  watch: {
    currentIndex() {
      this.updateColor();
    },
    mode() {
      this.toggleModal();
    },
  },
  methods: {
    handlePrevious() {
      this.currentIndex--;
    },
    handleNext() {
      this.$refs.character.triggerUp();
    },
    updateCharacter() {
      this.currentIndex === this.shuffledCharacters.length - 1 ? this.handleFinished() : this.currentIndex++;
    },
    handleFinished() {
      this.isFinished = true;
    },
    updateColor() {
      this.currentColorIndex === this.colors.length - 1 ? this.currentColorIndex = 0 : this.currentColorIndex++;
    },
    handleOffscreen() {
      this.updateCharacter();
    },
    toggleModal() {
      this.modalIsOpen = !this.modalIsOpen;
    },
    handleRestart() {
      this.currentIndex = 0;
      this.isFinished = false;
    }
  },
}
</script>