<template>
  <span class="origin-bottom">
    <slot />
  </span>
</template>

<script>
import gsap from 'gsap';

export default {
  emits: ['offscreen'],
  methods: {
    handleOffscreen() {
      this.$emit('offscreen');
    },
    triggerUp() {

      const tl = gsap.timeline();
      
      tl.to(
        this.$el, {
          scaleY: 0.5,
          duration: 0.1,

        });
      tl.to(
        this.$el, {
          scaleY: 1,
          y: '-90vh',
          duration: 0.3,
          ease: 'power2.out',
          onComplete: this.handleOffscreen,
        });
      tl.to(
        this.$el, {
          scaleY: 0.5,
          y: 0,
          duration: 0.3,
          delay: 0.2,
          ease: 'power2.in',
        });
      tl.to(
        this.$el, {
          scaleY: 1,
          duration: 0.1,
          ease: 'power2.in',
        });
    }
  },
}
</script>