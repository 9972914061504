<template>
  <div>
    <NumbersLetters />
  </div>
</template>

<script>
import NumbersLetters from '@/components/NumbersLetters.vue'

export default {
  name: 'HomeView',
  components: {
    NumbersLetters
  }
}
</script>
